import React from 'react';

const ListLink = ({ icon, data }) => {
    return (
        <ul className="list-unstyled">
            {data.map(item => (
                <li key={item.id} className="mb-2">
                    <a href={item.url} download className="text-decoration-none text-primary">
                        <i className={icon}></i> {item.item}
                    </a>
                </li>
            ))}
        </ul>
    );
};

export default ListLink;
