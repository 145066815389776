import { Link } from 'react-router-dom';

function CategoryGridList(props) {
  const data = [
    { id: 1, name: 'Food and Beverages - Peanut Butter, Cooking Oil, Drinks & Bottled Water', url: '#' },
    { id: 2, name: 'Soaps and Detergents – Laundry Soaps, Washing Powders, Hand Sanitizers & Cleaning Chemicals', url: '#' },
    { id: 3, name: 'Construction Materials – Deformed Bars, Cement, Concrete Products & Electrical Cables', url: '#' },
    { id: 4, name: 'Textile & Paints – Packaging Materials, Footwear, Paints, Varnishes and Inks', url: '#' },
    { id: 5, name: 'Petroleum Products – Lubricants and Fuels', url: '#' },
    { id: 6, name: 'Agriculture Products – Animal feeds and many more!', url: '#' },
   
  ];

  return (
    <>
      <div className={`category-grid-style ${props.className || ''}`}>
        {data.map((item, ind) => (
          <div key={ind} className="category-list-item">
            {props.link ? (
              <Link to={item.url}>{item.name}</Link>
            ) : (
              <span>{item.name}</span>
            )}
          </div>
        ))}
      </div>
    </>
  );
}

export default CategoryGridList;
