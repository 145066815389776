import React from 'react';
import Col from 'react-bootstrap/Col';
import ZaqaLogo from '../../assets/images/zaqa/ZAQA.webp';
import aboutImage2 from '../../assets/images/zaqa/zaqa2023.webp';
import ZATPLogo from '../../assets/images/zaqa/ZATP.webp';
import SectionTitle from '../../component/section-title/section-title';
import Counter from '../../component/counter/counter';
import FeatureStep from '../../component/feature-step/feature-step';
import { CategoryStepList, CategoryStepListAlt } from '../../data/category-step-list';
import { Row } from 'react-bootstrap';

function ZaqaSection() {
  return (
    <>
      <Col lg={6} className="mb-4 mb-lg-0">
        <SectionTitle
          extraClass=""
          title="The Zambia Annual Quality Awards (ZAQA), a flagship event of the Zambia Bureau of Standards (ZABS), are held annually to recognize and celebrate the achievements of companies and organizations that contribute to the advancement of quality in Zambia."
          content="These awards honor those who have implemented quality systems, procedures, and processes in line with local, regional and international best practices. Since its inception in 2015, the Quality Awards have grown into a highly anticipated event that highlights excellence in quality across various sectors. The awards are supported by the Zambia Agribusiness and Trade Project (ZATP)."
        />
      </Col>

      <Col lg={6}>
        <div className="row">
          <div className="col-sm-6">
            <img
              className="img-fluid border-radius mb-4 mt-4"
              src={ZATPLogo}
              alt="ZATP Logo"
            />
            <img
              className="img-fluid border-radius mb-4 mb-sm-0"
              src={aboutImage2}
              
            />
          </div>
          <div className="col-sm-6">
            <img
              className="img-fluid border-radius mb-4"
              src={ZaqaLogo}
              alt="ZAQA Logo"
            />
            <Counter
              className="counter-primary-bg"
              themeColor="counter-light"
              countStart="0"
              countEnd="8"
              countTitle="Award Categories"
            />
          </div>
        </div>
      </Col>

     
       <div className="col-12 col-xl-12 row">
          <h5 className="text-black" style={{ textAlign: 'justify', fontWeight: 'normal' }}>
            The quality awards competition seeks to recognize and appreciate organizations, businesses
            and individuals who are contributing to quality advancement in all sectors of Zambia
            by having measurement and quality systems, procedures and processes that are in line
            with local, regional and international practices and who use quality advancement to
            support national and regional economic development and growth.
          </h5>
          <h5 className="text-black" style={{ textAlign: 'justify', fontWeight: 'normal' }}>
            All businesses, manufacturers, service providers, and individuals across all sectors of the
            Zambian economy are invited to apply. Participants are provided with an application
            form based on their chosen category, which must be completed within the designated
            timeframe. The application, accompanied by a non-refundable fee of ZMW800.00 for large
            enterprises and ZMW300.00 for SMEs, is submitted to the Zambia Bureau of Standards (ZABS). An independent panel of judges then reviews the applications against set criteria, selecting winners accordingly. The awards culminate in a gala dinner where the winners are announced and those excelling in the SADC awards category gain the opportunity to participate and represent Zambia in the regional SADC awards the following year.
          </h5>
          <h5 className="text-black" style={{ textAlign: 'justify', fontWeight: 'normal' }}>
            Completed forms, along with the deposit slip for the non-refundable fee (ZMW300.00 for SMEs and ZMW800.00 for large enterprises), should be sent to
            <a href="mailto:cnzali@zabs.org.zm"> cnzali@zabs.org.zm</a> and
            <a href="mailto:mcmatongo@zabs.org.zm"> mcmatongo@zabs.org.zm</a>.
          </h5>

          <h5 className="text-black" style={{ textAlign: 'justify', fontWeight: 'normal' }}>
            The awards are divided into SADC and Agribusiness categories. The winners will be announced at the Quality Awards Gala on 22nd November 2024.
          </h5>
          <h4 className="text-secondary">
            The deadline for submissions is Thursday 31st October 2024.
          </h4>
        </div>


      {/* SADC Awards Section */}
      <Col lg={6}>
        <h2 className="mb-5 mt-5">SADC Awards</h2>
      </Col>
      <Col>
        <div className="feature-step-wrapper grid-wrapper grid-lg-4 grid-md-2 grid-sm-2 grid-xs-1">
          {CategoryStepList.map((val, ind) => (
            <FeatureStep
              key={ind}
              stepNumber={val.stepNumber}
              title={val.title}
              description={val.description}
              buttons={val.buttons}  // Pass buttons for FeatureStep
            />
          ))}
        </div>
      </Col>

      {/* Agribusiness Awards Section */}
      <Col lg={6}>
        <h2 className="mb-5 mt-5">Agribusiness Awards</h2>
      </Col>
      <Col>
        <div className="feature-step-wrapper grid-wrapper grid-lg-4 grid-md-2 grid-sm-2 grid-xs-1">
          {CategoryStepListAlt.map((val, ind) => (
            <FeatureStep
              key={ind}
              stepNumber={val.stepNumber}
              title={val.title}
              description={val.description}
              buttons={val.buttons}  // Pass buttons for FeatureStep
            />
          ))}
        </div>
      </Col>
    </>
  );
}

export default ZaqaSection;
