import featureStepImg1 from '../assets/images/services/services-img1.webp';
import featureStepImg2 from '../assets/images/services/services-img2.webp';
import featureStepImg3 from '../assets/images/services/services-img3.webp';
import featureStepImg4 from '../assets/images/services/services-img4.webp';



const CategoryStepList = [
  {
    id: 1,
    featureImg: featureStepImg1,
    stepNumber: '01',
    title: 'Company of the Year',
    description: 'Two awards, SME and Large Enterprise: Awarded to a company or organization that has proactively and effectively implemented quality principles.',
    buttons: [
      {
        btnClass: "btn-light-round mr-3",
        btnText: "Apply for Large Enterprise",
        btnURL: "/documents/COMPANY-OF-THE-YEAR-LE.doc"
      },
      {
        btnClass: "btn-light-round ml-3",
        btnText: "Apply for Small Medium Enterprise",
        btnURL: "/documents/COMPANY-OF-THE-YEAR-SME.doc"
      }
    ]
  },
  {
    id: 2,
    featureImg: featureStepImg2,
    stepNumber: '02',
    title: 'Product of the Year',
    description:
      'Two awards SME and Large Enterprise: Awarded to a product that best reflects a commitment to quality in its unique product features, packaging, labelling and marketing and promotion. ',
    buttons: [
      {
        btnClass: "btn-light-round mr-3",
        btnText: "Apply for Large Enterprise",
        btnURL: "/documents/PRODUCT-OF-THE-YEAR-LE.doc"
      },
      {
        btnClass: "btn-light-round ml-3",
        btnText: "Apply for Small Medium Enterprise",
        btnURL: "/documents/PRODUCT-OF-THE-YEAR-SME.doc"
      }
    ]
  },
  {
    id: 3,
    featureImg: featureStepImg3,
    stepNumber: '03',
    title: 'Service of the Year',
    description:
      'Two awards SME and Large Enterprise: Awarded to a locally-delivered service that best reflects a commitment to local production and quality.',
    buttons: [
      {
        btnClass: "btn-light-round mr-3",
        btnText: "Apply for Large Enterprise",
        btnURL: "/documents/SERVICE-OF-THE-YEAR-LE.doc"
      },
      {
        btnClass: "btn-light-round ml-3",
        btnText: "Apply for Small Medium Enterprise",
        btnURL: "/documents/SERVICE-OF-THE-YEAR-SME.doc"
      }
    ]
  },
  {
    id: 4,
    featureImg: featureStepImg4,
    stepNumber: '04',
    title: 'Exporter of the Year',
    description:
      'Two awards SME and Large Enterprise: Awarded to a company or organization that has made significant progress in commencing or expanding exports to new or wider markets by introducing quality in their business operations.',
    buttons: [
      {
        btnClass: "btn-light-round mr-3",
        btnText: "Apply for Large Enterprise",
        btnURL: "/documents/EXPORTER-OF-THE-YEAR-LE.doc"
      },
      {
        btnClass: "btn-light-round ml-3",
        btnText: "Apply for Small Medium Enterprise",
        btnURL: "/documents/EXPORTER-OF-THE-YEAR-SME.doc"
      }
    ]
  },


];

const CategoryStepListAlt = [
  {
    id: 1,
    featureImg: featureStepImg1,
    stepNumber: '01',
    title: 'Agro Supporting Company of the Year',
    description:
      'One award: Awarded to a company that has supported SMEs and LE’s in the agro processing sector through provision of different products and services that have added value to the success of companies in the sector i.e. through provision of equipment, inputs, technical assistance, insurance and/or financial services.',
    buttons: [
      {
        btnClass: "btn-light-round mr-3",
        btnText: "Apply for Agro Supporting Company",
        btnURL: "/documents/Zambian-Agro-Supporting-Company-of-the-Year.doc"
      }

    ]
  },
  {
    id: 2,
    featureImg: featureStepImg2,
    stepNumber: '02',
    title: 'Zambian Agro Exporter of the Year',
    description:
      'One award: Awarded to a Zambian Agro company that has consistently exported its products in the SADC, COMESA, Continental and the International market.',
      buttons: [
        {
          btnClass: "btn-light-round mr-3",
          btnText: "Apply for Zambian Agro Exporter",
          btnURL: "/documents/Zambian-Agro-Exporter-of-Year.doc"
        }
  
      ]
  },
  {
    id: 3,
    featureImg: featureStepImg3,
    stepNumber: '03',
    title: 'Female Agribusiness Entrepreneur of the Year',
    description:
      'One award: Awarded to a Zambian Female Entrepreneur who has stood out in the agribusiness sector through the provision of agro products and services ',
      buttons: [
        {
          btnClass: "btn-light-round mr-3",
          btnText: "Apply for Female Agribusiness Entrepreneur",
          btnURL: "/documents/Female-Agribusiness-Entrepreneur.doc"
        }
  
      ]
  },
  {
    id: 4,
    featureImg: featureStepImg4,
    stepNumber: '04',
    title: 'Young Entrepreneur of the Year',
    description:
      'One award: Awarded to a Young Zambian Entrepreneur who has consistently made significant contribution in the provision of quality goods and services as a young Zambian.',
      buttons: [
        {
          btnClass: "btn-light-round mr-3",
          btnText: "Apply for Young Entrepreneur",
          btnURL: "/documents/Young-Entrepreneur-of-the-Year.doc"
        }
  
      ]
  },


];

const FeatureTimelineList = [
  {
    id: 1,
    stepNumber: '01',
    title: 'Analysis',
    description:
      'Then with that thing in mind, follow these simple steps. Step One: Get yourself nice and relaxed and settled. Concentrate on your breathing, engage in the moment and spend some time being still, quiet and drift inside of your own mind. Hypnotize yourself here.',
  },
  {
    id: 2,
    stepNumber: '02',
    title: 'Design',
    description:
      'That is your imagination doing that. You know the sound that your feet make when you walk across gravel don’t you? You can imagine it, but you are not hearing it in your ears, are you? Just imagine these things as best as you can.',
  },
  {
    id: 3,
    stepNumber: '03',
    title: 'Development',
    description:
      'Become aware of the temperature, the sights, the sounds and enjoy walking along the path of your life. Make it sensory rich and get comfortable with the idea. Imagine the feeling of your feet walking along the path and the sound they make.',
  },
  {
    id: 4,
    stepNumber: '04',
    title: 'Testing',
    description:
      'Engage with the idea of really being there. Step Three: Imagine that a few more steps ahead there is a place where the path splits, where is goes off to the left and off to the right. Pause here for a few moments and have a think.',
  },
  {
    id: 5,
    stepNumber: '05',
    title: 'Go-Live',
    description:
      'Think about that as you stand at this place where the path splits. You want to make a decision and commit to one of these paths. Before you make that decision, we are going to see what each path holds for your future.',
  },
];

export default CategoryStepList;
export { CategoryStepListAlt }
export { CategoryStepList }
export { FeatureTimelineList };
