import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal, Button, Form } from 'react-bootstrap';

import PageHeader from '../../layouts/page-header/PageHeader';
import VideoPopup from '../../component/video';
import videoImg from '../../assets/images/about/zabsApproved.webp';
import certificationImg from '../../assets/images/about/zabsCertificationMark.webp';
import List from '../../component/list';
import SectionTitle from '../../component/section-title/section-title';
import CategoryGridList from '../../template/category-grid';
import ActionBox from '../../component/action-box';
import standards_list from "../../assets/json/standards_list_data.json";
import { useEffect, useState } from 'react';
import zabs_image from '../../assets/images/team/zabs.webp';
import downloadable_docs from '../../assets/json/certificaton_page_downloadable_documents.json';

function Standards () {
    const [activeTab, setActiveTab] = useState(0);
    const [scope, setScope] = useState("");
    const [isExpanded, setIsExpanded] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [comment, setComment] = useState("");

    const [formData, setFormData] = useState({
        name: "",
        contactNumber: "",
        email: "",
        comment: "",
    });

    const handleRowClick = (rowData) => {
        setSelectedRowData(rowData);
        setShowModal(true);
    };

    const handleClose = () => {
        setShowModal(false);
        setFormData({
            name: "",
            contactNumber: "",
            email: "",
            comment: "",
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSendComment = () => {
        const emailBody = `
            Name: ${formData.name}
            Contact Number: ${formData.contactNumber}
            Email: ${formData.email}
            Comment: ${formData.comment}
            Related Customer Name: ${selectedRowData?.customer_name}
            Related Location: ${selectedRowData?.location}
        `;

        const mailtoLink = `mailto:dingikbanda@gmail.com?subject=New Comment&body=${encodeURIComponent(emailBody)}`;
        window.location.href = mailtoLink;

        handleClose();
    };

    useEffect(() => {
        if (standards_list[activeTab]?.data?.length > 0)
        {
            setScope(standards_list[activeTab]?.data[0]?.scope);
        }
    }, [activeTab, standards_list]);

    const listItems1 = [
        { id: 1, item: 'Affordable and practical', url: '#' },
        { id: 2, item: 'Technical business support', url: '#' },
        { id: 3, item: 'Growth and market expansion opportunities', url: '#' },
    ];
    const listItems2 = [
        { id: 1, item: 'All Micro Small and Medium Enterprises in the food value chain', url: '#' },
        { id: 2, item: 'Designed for small and micro producers.', url: '#' },
        { id: 3, item: 'Any food or beverage company that wishes to have the certification.', url: '#' },
        { id: 4, item: 'Suppliers whose products are made or packed in the Zambia', url: '$' },
    ];


    const handleDownload = (path, filename, extension) => {
        // Assuming the file is stored locally and you know its path
        const filePath = path; // Replace with the actual path

        const link = document.createElement('a');
        link.href = `${process.env.PUBLIC_URL}/assets/documents/${path}`;
        link.download = `${filename}${extension}`; // This will set the file name when downloading
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    return (
        <>
            <div className="site-content">
                <PageHeader
                    title="Standards"
                    description="Zambia Bureau of Standards (ZABS) is the sole service provider for Standards 
                                Certification to the industry in Zambia."
                    breadCrumbItems={[
                        { label: 'Home', path: '/' },
                        { label: 'Pages', path: '#' },
                        { label: 'Standards', path: '#', active: true },
                    ]}
                />
                <div className="content-wrapper">
                    <section className="section-ptb">
                        <Container>
                            <Row>
                                {/* <Row className="justify-content-center mb-4 mt-md-5">
                                    <div className="col-xl-10">
                                        <h4 className="text-secondary">
                                            Zambia Bureau of Standards (ZABS) is the sole service provider for Standards
                                            Certification to the industry in Zambia.
                                        </h4>
                                        <p>
                                            Certification refers to confirmation or an attestation that products, processes, or
                                            systems of an organization meet the requirements of a standard or specification.
                                            ZABS provides assurance that products, services, and processes meet national or
                                            international standards/specifications. This is done through a review, assessment,
                                            or audit. ZABS offers two types of certification schemes namely Product
                                            Certification and Systems Certification
                                        </p>
                                        <p className="mb-0">
                                            ZABS offers two types of certification schemes namely Product Certification and Systems Certification
                                        </p>


                                        <hr className="light-bg" />
                                        <h4 className="mb-0 text-secondary">
                                            Product Certification
                                        </h4>
                                        <p className="mb-0">
                                            Under Product certification we ensure that a product that has passed a performance
                                            and quality assurance tests and meets the requirements of the set standard is issued
                                            with a quality mark.  Product certification is one of the quality assurances services
                                            that the Zambia Bureau of Standards (ZABS) provides to Industry. Through
                                            product certification, ZABS as a third party, attests that a product has met the
                                            requirements of a standard and is safe and fit for purpose.
                                        </p>
                                        <hr className="light-bg" />
                                        <h4 className="mb-0 text-secondary">
                                            Systems Certification
                                        </h4>
                                        <p className="mb-0">
                                            A Management System is a company’s structure for managing its processes and
                                            activities that transform inputs of resources into a product or service which meets
                                            the company’s objectives while satisfying the customers’ quality requirements,
                                            complying with regulations meeting environmental and public health objectives
                                        </p>


                                    </div>
                                </Row> */}

                                <Row className="justify-content-center mb-4 mt-md-5 w-100">
                                    {/* <h4 className="text-secondary">
                                        LIST OF STANDARDS
                                    </h4> */}
                                    <div>
                                        {/* Tab Navigation */}
                                        <nav className="nav nav-tabs mb-3">
                                            {standards_list?.map((tab, index) => (
                                                <button
                                                    key={index}
                                                    className={`nav-link text-dark ${index === activeTab ? "active text-light" : ""}`}
                                                    onClick={() => { setActiveTab(index); setIsExpanded(true); }}
                                                    style={
                                                        index === activeTab
                                                            ? { backgroundColor: "rgb(0,131,211)", color: "white" }
                                                            : {}
                                                    }
                                                >
                                                    {tab?.name}
                                                </button>
                                            )
                                        )
                                            }
                                            {/* <button
                                                className={`nav-link text-dark ${2 === activeTab ? "active text-light" : ""}`}
                                                onClick={() => setActiveTab(2)}
                                                style={
                                                    2 === activeTab
                                                        ? { backgroundColor: "rgb(0,131,211)", color: "white" }
                                                        : {}
                                                }
                                            >
                                                PUBLIC DOCUMENTS
                                            </button> */}
                                        </nav>
                                        {
                                            activeTab !== 2 &&
                                            (<>
                                                {/* <div className="mt-2 mb-4">
                                                    <div
                                                        className="d-flex align-items-center"
                                                        onClick={() => setIsExpanded(!isExpanded)}
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <i
                                                            style={{ marginRight: 10, fontSize: "1.2rem" }}
                                                            className={`fas ${isExpanded ? "fa-chevron-up" : "fa-chevron-right"}`}
                                                        />
                                                        <h5 className="text-secondary mb-0">CERTIFICATION ANNOUNCEMENT</h5>
                                                    </div>

                                                    {isExpanded && (
                                                        <p>
                                                            {standards_list[activeTab]?.certification_announcement}
                                                        </p>
                                                    )}
                                                </div> */}
                                            </>)
                                        }
                                        {/* Scope Radio Buttons */}
                                        <div className="mb-3">
                                            {/* {standards_list[activeTab]?.data?.map((item, index) => (
                                                <div key={index} className="form-check form-check-inline">
                                                    <div>
                                                        <input
                                                            type="radio"
                                                            id={`scope-radio-${index}`}
                                                            name="scope-options"
                                                            className="form-check-input"
                                                            checked={item?.scope === scope}
                                                            onChange={() => setScope(item?.scope)}
                                                        />
                                                    </div>
                                                    <label
                                                        htmlFor={`scope-radio-${index}`}
                                                        className={`form-check-label ${item?.scope === scope ? 'selected' : ''}`}
                                                        style={{
                                                            padding: "5px 10px",
                                                            borderRadius: "5px",
                                                            cursor: "pointer",
                                                            transition: "all 0.3s ease-in-out", // Add transition for smooth effects
                                                        }}
                                                    >
                                                        {item?.scope}
                                                    </label>
                                                </div>
                                            ))} */}
                                            {
                                                // activeTab !== 2 && (
                                                //     <>
                                                //         <div>
                                                //             <div className="card-body">
                                                //                 {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                                                //                 <div className='d-flex justify-content-start align-items-center'>
                                                //                     <a className="btn btn-danger mt-4"
                                                //                         href={standards_list[activeTab]?.path} download={standards_list[activeTab]?.dowloadable_name}>
                                                //                             <strong>Download List</strong>
                                                //                     </a>
                                                //                 </div>
                                                //             </div>
                                                //         </div>
                                                //     </>
                                                // )
                                            }
                                        </div>

                                        {
                                            activeTab === 1 ?
                                                <>
                                                    <div className="mt-4 grid-wrapper grid-lg-3 grid-md-3 grid-sm-3 grid-xs-1">
                                                        {
                                                            downloadable_docs?.map((doc, index) => {
                                                                return (
                                                                    <>
                                                                        <div className="card" style={{ height: '400px' }}>
                                                                            <img
                                                                                src={zabs_image}
                                                                                alt="card-image"
                                                                                className="img-fluid"
                                                                                style={{ height: '250px', objectFit: 'cover' }}
                                                                            />
                                                                            <div className="card-body">
                                                                                <h5 className="card-title">{doc?.name}</h5>
                                                                                {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                                                                                <div className='d-flex justify-content-end align-items-center'>
                                                                                    <a className="btn btn-danger mt-4"
                                                                                        href={doc?.path} download={doc?.dowloadable_name}><strong>Download</strong>
                                                                                    </a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <>
                                                {/* Table */}
                                                <div className="table-responsive border"  style={{ width: '100%' }}>
                                                    <table className="table table-hover border table-sm align-middle table-responsive"  style={{ width: '100%' }}>
                                                        <thead className="bg-primary border table-light">
                                                            <tr>
                                                                <th scope="col" className="p-2 text-center font-md">STANDARD CODE</th>
                                                                <th scope="col" className="p-2 text-center font-md">NAME OF STANDARD </th>
                                                                <th scope="col" className="p-2 text-center font-md">DESRIPTION </th>
                                                                <th scope="col" className="p-2 text-center font-md"style={{ width: '10%' }}>PRICE CODE  </th>
                                                                <th scope="col" className="p-2 text-center font-md" style={{ width: '10%' }}>YEAR</th>
                                                                {/* Add other columns as needed */}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {standards_list[activeTab]?.data
                                                                ?.filter(item => item.scope === scope)
                                                                ?.map((item, index) =>
                                                                    item?.data?.map((certificate, certIndex) => (
                                                                        <tr
                                                                            className="clickable-row"
                                                                            key={certIndex}
                                                                            onClick={() => handleRowClick(certificate)}
                                                                        >
                                                                            <td className="px-4 fw-medium font-md text-gray-900 whitespace-nowrap">{certificate?.code}</td>
                                                                            <td className="px-4 fw-medium font-md text-gray-900 whitespace-nowrap">{certificate?.title}</td>
                                                                            <td className="px-4 fw-medium font-md text-gray-900 whitespace-nowrap">{certificate?.description}</td>
                                                                            <td className="px-4 fw-medium font-md text-gray-900 whitespace-nowrap">{certificate?.priceCode}</td>
                                                                            <td className="px-4 fw-medium font-md text-gray-900 whitespace-nowrap">{certificate?.year}</td>
                                                                            {/* Add other row data */}
                                                                        </tr>
                                                                    ))
                                                                )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                    
                                                {/* Modal */}
                                                <Modal show={showModal} onHide={handleClose}>
    <Modal.Header closeButton>
        <div style={{ fontWeight: "bold", borderBlockColor: "grey" }}>
            <Modal.Title>Add a Comment</Modal.Title>
        </div>
    </Modal.Header>
    <Modal.Body style={{ backgroundColor: "#f8f9fa", border: "1px solid lightgrey" }}>
        <Form>
            <Form.Group className="mb-3">
                <Form.Label style={{ fontWeight: "bold" }}>Name</Form.Label>
                <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Enter your name"
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label style={{ fontWeight: "bold" }}>Contact Number</Form.Label>
                <Form.Control
                    type="text"
                    name="contactNumber"
                    value={formData.contactNumber}
                    onChange={handleChange}
                    placeholder="Enter your contact number"
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label style={{ fontWeight: "bold" }}>Email Address</Form.Label>
                <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Enter your email address"
                    required
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label style={{ fontWeight: "bold" }}>Comment</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={3}
                    name="comment"
                    value={formData.comment}
                    onChange={handleChange}
                    placeholder="Enter your comment"
                    required
                />
            </Form.Group>
        </Form>
    </Modal.Body>
    <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
            Close
        </Button>
        <Button variant="primary" onClick={handleSendComment} disabled={!formData.name || !formData.contactNumber || !formData.email || !formData.comment}>
            Send Comment
        </Button>
    </Modal.Footer>
</Modal>
                                            </>
                                        }
                                    </div>
                                </Row>
                            </Row>
                        </Container>
                    </section >

                    <Row className="justify-content-center">
                    </Row>

                    <section
                        className="section-pt bg-secondary-half"
                        data-bg-height="600"
                    >
                        <Container>
                            <Row className="justify-content-center">
                                <Col xl={10} lg={11}>
                                    <SectionTitle
                                        extraClass="section-title-light text-center"
                                        title="Become a Certified Local Supplier."
                                        content="The Certified Local Supplier Scheme (CLSS) supports smaller, specialized producers 
                                        by providing guidance, mentorship, and training services to help them meet quality and food 
                                        safety standards required by high-value domestic supermarkets."
                                    />
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col lg={8}>
                                    <VideoPopup
                                        videoURL="https://www.youtube.com/embed/Nq22KQvS7go"
                                        videoImage={videoImg}
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="section-ptb">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <SectionTitle
                                        title="Certified Local Supplier"
                                        content="The Certified Local Supplier Scheme (CLSS) supports smaller, 
                                        specialized producers by providing guidance, mentorship, and training 
                                        services to help them meet quality and food safety standards required 
                                        by high-value domestic supermarkets."
                                    />
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={5} className="pe-lg-5 mb-4 mb-md-0">
                                    <div className="mb-4">
                                        <span className="display-6 text-primary fw-bold">Why this Scheme?</span>
                                        <p>The CLSS helps local suppliers improve processes, product quality,
                                            packaging, and employee training, ensuring continuous improvement.
                                            It boosts buyer and consumer confidence in the safety and hygiene
                                            of local suppliers' products while providing cost-effective testing
                                            and certification to maintain competitiveness.</p>
                                        <hr className="light-bg" />
                                    </div>
                                    <div className="mb-4">
                                        <span className="display-5 text-primary fw-bold">
                                            Services Provided
                                        </span>
                                        <p>The scheme offers mentoring, food safety training, product testing, evaluation,
                                            packaging, labeling guidance, and adherence to local laws.</p>

                                        <hr className="light-bg" />
                                    </div>
                                    <div>
                                        <span className="display-5 text-primary fw-bold">
                                            Benefits
                                        </span>
                                        <p>What are the benefits of the scheme?</p>
                                        <List icon="fas fa-check" data={listItems1} />
                                    </div>
                                </Col>
                                <Col md={6} className="ps-lg-5">
                                    <h3 className="mb-4">
                                        Who is eligible for the scheme?
                                    </h3>
                                    <Row>
                                        <Col sm={12}>
                                            <List icon="fas fa-check" data={listItems2} />
                                        </Col>
                                    </Row>
                                    <div className="mb-4 mt-md-5">
                                        <img
                                            className="border-radius-5 w-100"
                                            src={certificationImg}
                                            alt=""
                                        />

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                    <section className="section-ptb">
                        <Container>
                            <Row>
                                <SectionTitle
                                    extraClass=""
                                    title="ZABS Certified Products"
                                    content="ZABS has certified over 150 different products ranging from food and beverages to 
                                             construction materials and Petroleum products. ZABS certifies a wide range of 
                                             products including."
                                />
                                <CategoryGridList
                                    className="grid-wrapper grid-lg-4 grid-md-3 grid-sm-2 grid-xs-1"
                                    link={false}
                                />
                            </Row>
                        </Container>
                    </section>
                    <section className="section-pb">
                        <Container>
                            <Row>
                                <Col sm={12}>
                                    <ActionBox
                                        btnClass="btn-primary-round text-light"
                                        title="Want to know on how you can certify your products? "
                                        content="Contact the ZABS Training and Certification section"
                                        btnText="Contact Us"
                                        btnURL="/contact"
                                    />
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </div >
            </div >
        </>
    );
}

export default Standards;